import { Button, Box, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import History from '../components/ServerHistory2'


const HistoryPage = () => {

  const pageTitle = "Servers History"

  const pageDesc = "Historical data on Shack Servers"

  return (
  <Layout pageTitle={pageTitle}>
    <SEO desc={pageDesc} title={pageTitle} />
      <Container>
      <Typography variant="h3" style={{ color: "white" }}>
          Shack Servers History
          </Typography>
      </Container>
      <History/>
      <Divider />

    </Layout>
  )
}

export default HistoryPage
