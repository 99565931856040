import { Box, makeStyles, Button, Radio, Select, FormControl, MenuItem, Checkbox, InputLabel, ListItemIcon, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import * as api from '../api/calls'
import { Area, AreaChart, Legend, Tooltip, XAxis, YAxis, CartesianGrid, PieChart, Pie, Cell, ResponsiveContainer, LineChart, Line } from 'recharts';

const useStyles = makeStyles({
    legend: {
        fontSize: '0.5rem'
    },
    toolTitle: {
        color: 'white',
        borderBottom: 'solid white'
    },
    toolBox: {
        backgroundColor: 'rgba(0,0,0,0.3)'
    },
    toolTable: {
        '& td': { padding: '0 0 0 0', color: 'rgba(255,255,255,0.8)' },
        margin: 0
    },
    pie: {
        // marginInline:'auto'
    }
})

const colorPalette = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const myChart = () => {

    const classes = useStyles()

    const [historyData, setHistoryData] = useState()
    const [is100, setIs100] = useState(false)
    const [selected, setSelected] = useState([]);

    const getData = async () => {
        const thisHistoryData = await api.getServerHistoryByGameMode()
        const formattedHistoryData = thisHistoryData.map(d => {
            const unixtime = new Date(d.creationtime).getTime()
            const players = parseInt(d.sum, 10)
            return { ...d, unixtime, players }
        })

        // const groupedData = groupData(formattedHistoryData)
        // console.log(formattedHistoryData)
        setHistoryData(formattedHistoryData)
    }

    const groupData = (data) => {
        // console.log(data)
        const firstObject = {
            unixtime: data[0].unixtime,
            creationtime: data[0].creationtime,
            [data[0].gameMode]: data[0].players,
            totalPlayers: data[0].players
        }
        let groupedData = [{ ...firstObject }]

        data.forEach(d => {

            // const groupedCreationIndex = groupedData.findIndex(g=> g.creationime === d.creationtime)
            const lastAdded = groupedData[groupedData.length - 1]


            if (lastAdded.creationtime == d.creationtime) {
                lastAdded[d.gameMode] = d.players
                lastAdded.totalPlayers += d.players
            } else {
                const { creationtime, players, gameMode, unixtime } = d
                groupedData.push({ creationtime, [gameMode]: players, unixtime, totalPlayers: players })
            }
        })
        return groupedData
    }

    const getAggData = (thisHistoryData) => {

        let aggData = []
        thisHistoryData.forEach(d => {
            const repeatGameModeI = aggData.findIndex(a => a.gameMode == d.gameMode)
            if (repeatGameModeI > -1) {
                //add
                aggData[repeatGameModeI].players += d.players
            } else {
                const { gameMode, players } = d
                aggData.push({ gameMode, players })
            }
        })
        return aggData
    }

    useEffect(() => {
        getData()
    }, [])

    const dateFormatter = (str) => {
        const dateObj = new Date(str)
        const dateFormatted = `${dateObj.toString().slice(16, 18)}:00`
        return dateFormatted
    }

    const dateFormatter2 = (str) => {
        const dateObj = new Date(str)
        const dateFormatted = `${dateObj.toString().slice(0, 10)}`
        return dateFormatted
    }

    const dayTicks = (data) => {
        const creationDays = data.map(d => {
            const thisDay = new Date(new Date(d.unixtime).toDateString()).getTime()
            return thisDay
        })
        const uniqueCreationDays = Array.from(new Set(creationDays))
        return uniqueCreationDays.slice(0, -1)
    }

    const group100 = (data) => {

        const groupedData = groupData(data)
        const groupedData100 = groupedData.map(d => {
            const gms = Object.keys(d).filter(k => !['creationtime', 'totalPlayers', 'unixtime'].includes(k))

            const totalPlayers = d.totalPlayers
            gms.forEach(g => {
                d[g] = Math.round((d[g] / totalPlayers)*100)/100
            })
            return d
        })
        // console.log(groupedData100)
        return groupedData100
    }

    const renderCustomizedLabel = (props) => {
        console.log(props)
        const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name } = props
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {percent > 0.05 ? `${(percent * 100).toFixed(0)}% ${name}` : ``}
            </text>
        );
    };

    const handle100 = () => {
        setIs100(!is100)
    }

    const sortedAggData = historyData && getAggData(historyData).sort((a, b) => a.players - b.players)

    return (
        <Box>
            Options:
            <Button variant="outlined" onClick={handle100}>Toggle 100%</Button>

            <Box pt={3}>Past Week:</Box>

            {historyData &&
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={is100 ? group100(historyData) : groupData(historyData)}>
                        <XAxis
                            dataKey={"unixtime"}
                            tickFormatter={dateFormatter2}
                            type="number"
                            domain={['dataMin', 'dataMax']}
                            scale={"time"}
                            // xAxisId={1}
                            interval="preserveStartEnd"
                            ticks={dayTicks(groupData(historyData))}
                        />

                        <Legend verticalAlign='middle' content={<CustomLegend />} layout='vertical' align='right' />
                        <YAxis type="number" domain={is100 ? [0, 1] : ['dataMin', 'dataMax']} allowDataOverflow/>
                        <CartesianGrid strokeDasharray="5 1 1" />
                        <Tooltip content={<CustomToolTip />} />
                        {sortedAggData.map((d, i) => (
                            <Area key={d.gameMode} connectNulls dataKey={d.gameMode} stackId={1} stroke={colorPalette[i]} fill={colorPalette[i]} />
                        ))}
                    </AreaChart>

                </ResponsiveContainer>

            }
            {historyData &&
                <ResponsiveContainer width="100%" height={300}>
                        <PieChart className={classes.pie} width={500} height={300} >
                            <Pie
                                // labelLine={false}
                                label={<MyPieLabel />}
                                data={sortedAggData}
                                nameKey={"gameMode"}
                                dataKey={"players"}>
                                {sortedAggData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colorPalette[index]} />
                                ))}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
            }
        </Box>
    )
}

const MyPieLabel = (props) => {
    // console.log(props)
    const { x, y, midAngle, innerRadius, outerRadius, percent, index, name } = props
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    // const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill='white'>
            {`${(percent * 100).toFixed(0)}% ${name}`}
        </text>
    );
}

const CustomToolTip = ({ active, payload }) => {
    const classes = useStyles()
    const myTime = active && new Date(payload[0].payload.creationtime)
    // const dayStr = active && dayNames[myTime.getDay()]
    // const myTimeStr = active && `${dayStr} ${myTime.getHours()}h`
    const myTimeStr = myTime.toString().slice(0, -29)
    const orderedPayload = payload.sort((a, b) => b.value - a.value)
    // console.log(orderedPayload)
    return (
        <Box className={classes.toolBox}>
            <table className={classes.toolTable}>
                <thead>
                    <tr className={classes.toolTitle}>
                        <th>{active && myTimeStr}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={classes.toolTitle}>
                        <td>{`Total:`}</td>
                        <td>{payload.reduce((prev, cur) => cur.value + prev, 0)}</td>
                    </tr>

                    {orderedPayload.map(p => (
                        <tr key={p.name}>
                            <td>{p.name}</td><td>{p.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </Box>
    )
}

const CustomLegend = ({ payload }) => {
    const sortedPayload = payload.reverse()
    return (
        <Box p={2}>{sortedPayload.map(p => (<Box key={p.value} style={{ "color": p.color }}>{p.value}</Box>))}</Box>
    )
}

export default myChart

